import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const GeneralSection = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  return (
    <div className="contact-info__column contact-info__column--general" ref={componentRef}>
      <h3 className="contact-info__column__title">{data.title}</h3>
      <div className="contact-info__address">
        <p className="contact-info__address__line contact-info__address__line--1">{data.address_1}</p>
        <p className="contact-info__address__line contact-info__address__line--2">{data.address_2}</p>
        <p className="contact-info__address__line contact-info__address__line--3">{data.address_3}</p>
      </div>
      <a href={`tel:${data.phone_number}`} className="contact-info__phone">{data.phone_number}</a>
    </div>
  )
}

const NewBusinessSection = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  const splitEmail = data.email.split('@')

  return (
    <div className="contact-info__column contact-info__column--new-business" ref={componentRef}>
      <h3 className="contact-info__column__title">{data.title}</h3>
      <div className="contact-info__contact">
        <p className="contact-info__contact__name">{data.point_of_contact}</p>
        <a href={`mailto:${data.email}`} className="contact-info__contact__email"><span className="contact-info__contact__email__local">{splitEmail[0]}</span>{`@${splitEmail[1]}`}</a>
      </div>
    </div>
  )
}

const PressSection = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })
  const splitEmail = data.email.split('@')

  return (
    <div className="contact-info__column contact-info__column--press" ref={componentRef}>
      <h3 className="contact-info__column__title">{data.title}</h3>
      <div className="contact-info__contact">
        <p className="contact-info__contact__name">{data.point_of_contact}</p>
        <a href={`mailto:${data.email_local + data.email_domain}`} className="contact-info__contact__email"><span className="contact-info__contact__email__local">{splitEmail[0]}</span>{`@${splitEmail[1]}`}</a>
      </div>
    </div>
  )
}

const ContactInfo = ({ data }) => {
  return (
    <section className="contact-info">
      <div className="contact-info__container">
        <div className="contact-info__grid">
          <GeneralSection data={data.general_section} />
          <NewBusinessSection data={data.new_business_section} />
          <PressSection data={data.press_section} />
        </div>
      </div>
    </section>
  )
}

export default ContactInfo
