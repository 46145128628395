import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'

import ContactHero from '~components/pages/contact/Hero'
import ContactInfo from '~components/pages/contact/ContactInfo'

const ContactPageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <ContactHero data={data.hero} isPreview={isPreview} />
      <ContactInfo data={data.contact_info} isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

export default ContactPageWrap
