import React from 'react'

import Layout from '~global/Layout'
import ContactPageWrap from '~pageWraps/ContactPageWrap'

import data from '../content/contacts.yaml'

const Contact = () => {
  return (
    <Layout>
      <ContactPageWrap data={data}/>
    </Layout>
  )
}

export default Contact
